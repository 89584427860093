import { formatImgUrl } from 'constants/configs/theme.config'

import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import { PrimaryButton, SecondaryButton, SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"

import { appActions } from "../../store/app"
import { documentsActions } from "../documents/documentsSlice"
import { THEME, updateUserDataThunk } from "../user/userSlice"
import { getUiTheme, getUserDataSelector } from "../user/selectors"
import { PageTopbar } from "../common/page-topbar"
import { useAppDispatch } from "../../store"
import { getContentTypesUploadSelector } from "../common/selectors"
import { uploadTimelineItemsThunk } from "../common/files-uploading/filesUploadingSlice"
import { SDK_TYPES } from "../../sdk/sdkConstants"
import { analytics, ANALYTICS_EVENTS } from "../common/analytics"
import { useMobileDetection } from "../common/hooks"

import { getUserHasTimelineItemsSelector } from "./selectors"
import { checkTimelineItemsCountThunk, createFamilyCloudThunk, familyActions } from "./familySlice"

export const CreateFamilyPlaceholder = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userHasTimelineItems = getUserHasTimelineItemsSelector()
  const contentTypesUpload = getContentTypesUploadSelector()
  const userData = getUserDataSelector()

  const theme = getUiTheme()

  useEffect(() => {
    dispatch(appActions.toggleLayoutOverflowEnabling(true))
    dispatch(appActions.setIsRightSidebarTransparent(true))
    dispatch(checkTimelineItemsCountThunk())

    return () => {
      dispatch(appActions.toggleLayoutOverflowEnabling(false))
      dispatch(documentsActions.setShowCreateModal(false))
      dispatch(appActions.setIsRightSidebarTransparent(false))
    }
  }, [])

  const handleOpenInviteModal = () => {
    dispatch(familyActions.toggleFamilyinviteModal(true))
  }

  const handleUploadPhotos = (e) => {
    const files = e.target.files

    const callback = (familyUserId, ownerId) => {
      dispatch(uploadTimelineItemsThunk({ files, type: SDK_TYPES.FAMILY }))
      dispatch(updateUserDataThunk({ family_user_id: familyUserId, is_owner_family: userData?.id === ownerId }))
      analytics.push(ANALYTICS_EVENTS.WEB_FC_PHOTO_UPLOAD_CLICK)
    }

    dispatch(createFamilyCloudThunk({ callback }))
  }

  const handleNavigateToTimelineForAddItems = () => {
    navigate('/family/photos/add')
  }

  return (
    <SCreateFamilyPlaceholderWrapper>
      <SCreateFamilyPlaceholderBox>
        <InfoContent>
          <InfoContentLeft>
            <InfoTitle>
              {t('l_familycloud_WelcomeTitle')}
            </InfoTitle>

            <InfoDescription>
              {t('l_familycloud_WelcomeDescription')}
            </InfoDescription>

            <SButtonsBox>
              {userHasTimelineItems && (
                <SSecondaryButton
                  actionName={t('a_common_upload')}
                  onAction={handleNavigateToTimelineForAddItems}
                />
              )}

              {!userHasTimelineItems && (
                <SUploadButton
                  htmlFor="timeline-upload"
                >
                  {t('a_common_upload')}

                  <SHiddenFilesInput
                    id="timeline-upload"
                    onChange={handleUploadPhotos}
                    type="file"
                    multiple
                    accept={contentTypesUpload}
                  />
                </SUploadButton>
              )}

              <SPrimaryButton
                actionName={t('a_familycloud_inviteMembers')}
                onAction={handleOpenInviteModal}
              />
            </SButtonsBox>
          </InfoContentLeft>

          <InfoContentRight>
            <InfoImg
              src={theme === THEME.LIGHT ? formatImgUrl("/assets/placeholders-images/family-create.svg") : formatImgUrl("/assets/placeholders-images-black/family-create.svg")}
            />
          </InfoContentRight>
        </InfoContent>

        <SFullWidthDivider />

        <SInfoOptionsContent>
          <SOptionColumn>
            <SOptionIcon iconName='multiple_actions_add_2' />

            <div>
              <SOptionTitle>
                {t('l_familycloud_inviteFamily')}
              </SOptionTitle>

              <SOptionDescription>
                {t('l_familycloud_inviteFamilyDescription')}
              </SOptionDescription>
            </div>
          </SOptionColumn>

          <SOptionColumn>
            <SOptionIcon iconName='picture_polaroid_landscape' />

            <div>
              <SOptionTitle>
                {t('l_familycloud_viewTogether')}
              </SOptionTitle>

              <SOptionDescription>
                {t('l_familycloud_viewTogetherDescription')}
              </SOptionDescription>
            </div>
          </SOptionColumn>

          <SOptionColumn>
            <SOptionIcon iconName='responsive_design' />

            <div>
              <SOptionTitle>
                {t('l_familycloud_accessEasy')}
              </SOptionTitle>

              <SOptionDescription>
                {t('l_familycloud_accessEasyDescription')}
              </SOptionDescription>
            </div>
          </SOptionColumn>
        </SInfoOptionsContent>
      </SCreateFamilyPlaceholderBox>
    </SCreateFamilyPlaceholderWrapper>
  )
}

export const withCreateFamilyPlaceholder = (Component: React.FC, titleKey = '', mobileTitleKey = '') => () => {
  const { t } = useTranslation()
  const userData = getUserDataSelector()
  const isMobile = useMobileDetection(444)
  const [title, setTitle] = useState(titleKey)

  useEffect(() => {
    if(!!mobileTitleKey) {
      setTitle(isMobile ? mobileTitleKey : titleKey)
    } else {
      setTitle(titleKey)
    }
  }, [isMobile])

  if (!userData?.family_user_id) {
    return (
      <>
        {!!titleKey && (
          <SPageTopbar
            title={t(title)}
          />
        )
        }

        <CreateFamilyPlaceholder />
      </>
    )
  }

  return <Component />
}

const SCreateFamilyPlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  position: relative;
  width: 100%;
  left: 0;
  height: calc(100% - 80px);
  overflow: hidden;
  z-index: 13;  

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    padding-top: 20px;
    width: calc(100% + 290px);
    left: -36px;
    overflow: auto;
  }
`


const SCreateFamilyPlaceholderBox = styled.div`
  width: calc(100% - 32px);
  background: var(--background-secondary);
  min-height: 468px;
  border: 1px solid var(--divider-primary);
  border-radius: 12px;
  top: 30px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    position: absolute;
    min-height: unset;
    left: 36px;
    width: calc(100% - 66px);
  }
`

const SOptionColumn = styled.div`
  flex: 1;
  display: flex;

  & ~ & {
    margin-top: 24px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    display: block;
    
    & ~ & {
      margin-top: 0;
      margin-left: 48px;
    }
  }
`

const SOptionIcon = styled(SpriteIcon)`
  margin-right: 12px;
  width: 24px;
  height: 24px;
  color: var(--active-color, var(--button-primary-default));

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-right: 8px;
  }
`

const SOptionTitle = styled.p`
  color: var(--text-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 12px;
  }
`

const SOptionDescription = styled.p`
  margin-top: 4px;
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const InfoContent = styled.div`
  width: 100%;
  max-width: 1118px;
  padding: 0 24px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    flex-direction: row;
    padding: 60px 60px 0;
    align-items: unset;
  }
`

const SInfoOptionsContent = styled.div`
  width: 100%;
  max-width: 1118px;
  padding: 40px 24px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    display: flex;
    margin-top: 25px;
    flex-direction: row;
    padding: 0 60px 52px;
  }
`

const SFullWidthDivider = styled.div`
  margin-top: 40px;
  width: 100%;
  border-top: 1px solid var(--divider-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 87px;
  }
`

const InfoContentLeft = styled.div`
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    width: 448px;
    padding-top: 53px;
    display: block;
  }
`

const InfoContentRight = styled.div`
  width: 294px;
  padding-top: 32px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-left: 20px;
    width: 543px;
    padding-top: 0;
  }
`

const InfoImg = styled.img`
  width: 294px;
  height: auto;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    width: 443px;
  }

  @media (min-width: 1380px) {
    width: 543px;
    height: 320px;
  }
`

const InfoTitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-primary);
  text-align: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    font-size: 32px;
    line-height: 130%;
    text-align: unset;
  }
`

const InfoDescription = styled.p`
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--text-primary);
  text-align: center;
  max-width: 295px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 165%;
    width: unset;
    max-width: unset;
    text-align: unset;
  }
`

const SButtonsBox = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
  }
`

const SPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  height: 40px;
  min-width: 144px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-left: 16px;
    width: unset;
  }
`

const SSecondaryButton = styled(SecondaryButton)`
  margin-top: 16px;
  width: 100%;
  height: 40px;
  min-width: 144px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 0;
    width: unset;
  }
`

const SUploadButton = styled.label`
  margin-top: 16px;
  background: transparent;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--text-primary);
  padding: 0 32px;
  cursor: pointer;
  height: 40px;

  &:hover {
    background-color: var(--button-secondary-hover)
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 0;
    width: unset;
  }
`

const SHiddenFilesInput = styled.input`
  display: none;
`

const SPageTopbar = styled(PageTopbar)`
  && {
    h2 {
      white-space: unset;
    }
  }
  
`
