import { LOADING_STATUSES } from "constants/loadingStatuses"

import React, { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import {
  addTimelineItemsToFamilyThunk,
  loadTimelineItemsFirstTimeThunk,
  timelineActions
} from "features/photo/timeline/timelineSlice"
import { Timeline } from "features/photo/timeline/Timeline"
import {
  getTimelineLoadingStatusSelector,
  getTimelineSelectedItemsCountSelector,
  getTimelineSelectedItemsSelector
} from "features/photo/timeline/selectors"
import { useNavigate } from "react-router-dom"
import { SDK_TYPES } from "sdk/sdkConstants"
import {
  PageTimelineSkeleton,
  PrimaryButton,
  SpriteIcon,
  STYLED_VARIABLES
} from "@cloudike/web_ui_components"
import { useMobileDetection } from "features/common/hooks"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import classNames from "classnames"

import { appActions } from "../../../store/app"
import { updateUserDataThunk } from "../../../features/user/userSlice"
import { createFamilyCloudThunk } from "../../../features/family/familySlice"
import { uploadTimelineItemsThunk } from "../../../features/common/files-uploading/filesUploadingSlice"
import { getContentTypesUploadSelector } from "../../../features/common/selectors"
import { getUserDataSelector } from "../../../features/user/selectors"

const AddingPhotosToFamilyCloudPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const status = getTimelineLoadingStatusSelector()
  const contentTypesUpload = getContentTypesUploadSelector()
  const userData = getUserDataSelector()

  const isMobile = useMobileDetection(1024)

  usePageTitle('l_familycloud_manageFamily')

  useEffect(() => {
    dispatch(appActions.toggleLayoutSidebarsVisibility(false))
    dispatch(timelineActions.setCurrentTimelineType(SDK_TYPES.DEFAULT))
    dispatch(loadTimelineItemsFirstTimeThunk())

    return () => {
      dispatch(appActions.toggleLayoutSidebarsVisibility(true))
      dispatch(timelineActions.resetState())
    }
  }, [])

  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const handleBack = () => {
    navigate('/family/photos')
  }

  const handleAddPhotos = () => {
    const callback = (familyUserId, ownerId) => {
      dispatch(updateUserDataThunk({ family_user_id: familyUserId, is_owner_family: userData?.id === ownerId }))
      dispatch(appActions.toggleRightMenuOnMobile(false))

      const callback = () => navigate('/family/photos')

      dispatch(addTimelineItemsToFamilyThunk({ items: selectedItems, callback }))
    }

    dispatch(createFamilyCloudThunk({ callback }))
  }

  const handleUploadPhotos = (e) => {
    const files = e.target.files

    const callback = (familyUserId, ownerId) => {
      dispatch(uploadTimelineItemsThunk({ files, type: SDK_TYPES.FAMILY }))
      dispatch(updateUserDataThunk({ family_user_id: familyUserId, is_owner_family: userData?.id === ownerId }))

      navigate('/family/photos')
    }

    dispatch(createFamilyCloudThunk({ callback }))
  }

  return (
    <>
      <SPageTopbarBox
        title={t('l_family_addToFamily')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
        description={!isMobile ? t('l_family_timelineMessage') : ''}
        onBack={handleBack}
        isMobile={isMobile}
        isDescriptionShownIfSelectedFileExists
        rightContent={(
          <SRightTopbarContent>
            <SUploadButton
              htmlFor="timeline-upload"
            >
              {t('a_common_addFromDevice')}

              <SHiddenFilesInput
                id="timeline-upload"
                onChange={handleUploadPhotos}
                type="file"
                multiple
                accept={contentTypesUpload}
              />
            </SUploadButton>

            <SPrimaryButton actionName={t('a_common_done')}
              disabled={!selectedItemsCount}
              onAction={handleAddPhotos}
            />

            <SUploadBtnMobile htmlFor="timeline-upload-mobile">
              <SpriteIcon iconName="add_photo_alternate" />

              <SHiddenFilesInput
                id="timeline-upload-mobile"
                onChange={handleUploadPhotos}
                type="file"
                multiple
                accept={contentTypesUpload}
              />
            </SUploadBtnMobile>

            <SDoneBtnMobile
              className={classNames({ disabled: !selectedItemsCount })}
              onClick={handleAddPhotos}
            >
              <SpriteIcon iconName="check_24px" />
            </SDoneBtnMobile>
          </SRightTopbarContent>
        )}
      />

      {
        status === LOADING_STATUSES.LOADING &&
        <StyledSkeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <STimeline
            type={SDK_TYPES.DEFAULT}
            withDisabledPreview
            withControls={false}
          />
        )
      }
    </>
  )
}

const StyledSkeleton = styled(PageTimelineSkeleton)`
  padding: 0;
`

const SPageTopbarBox = styled(PageTopbar)`
  background: var(--background-primary);
  align-items: flex-start;
  height: 60px;
  
  h2 {
    font-size: 18px;
  }
    
  .page-topbar-cross-btn {
    margin-right: 12px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    height: 80px;
    
    h2 {
      font-size: 24px;
    }

    .page-topbar-cross-btn {
      margin-right: 16px;
    }
  }
`

const STimeline = styled(Timeline)`
  margin-top: 0;

  .top-to-family{
    position: fixed;
    width: calc(100% - 52px);
    top: 60px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    margin-top: 0;
    
    .top-to-family{
      top: 80px;
    }
  }
`

const SRightTopbarContent = styled.div`
  display: flex;
  align-items: center;
  padding-right: 40px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    padding-right: 0;
  }
`

const SPrimaryButton = styled(PrimaryButton)`
  margin-left: 16px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const SUploadButton = styled.label`
  background: transparent;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--text-primary);
  padding: 0 32px;
  cursor: pointer;
  height: 40px;

  &:hover {
    background-color: var(--button-secondary-hover)
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 0;
    width: unset;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const SHiddenFilesInput = styled.input`
  display: none;
`

const SUploadBtnMobile = styled.label`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 50%;
  margin-right: 17px;
  color: var(--text-secondary);
  
  &.disabled {
    background-color: #f5f7f9;
    color: var(--text-secondary);
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const SDoneBtnMobile = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: 0;
  border-radius: 50%;
  margin-right: 17px;
  background: var(--brand-normal);
  color: var(--background-tertiary);
  
  &.disabled {
    background-color: #f5f7f9;
    color: var(--text-secondary);
    pointer-events: none;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

export default AddingPhotosToFamilyCloudPage
