import React, { useCallback, useEffect, useState, useRef } from 'react'

import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { useUploadingFinishDetection } from 'features/common/files-uploading/useUploadingFinishDetection'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { useTranslation } from 'react-i18next'
import { uploadFavoriteItemsThunk, uploadTimelineItemsThunk } from 'features/common/files-uploading/filesUploadingSlice'
import { PageSpiner, PhotoCard, PhotosGrid, PhotosGroupTitle, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import classNames from 'classnames'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'
import { analyticsService } from 'features/common/analytics/analyticsService'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import { useNavigate } from "react-router-dom"
import _ from "lodash"

import { openPhotoPreviewThunk, photoPreviewActions, PREVIEW_TYPES } from '../photo-preview/photoPreviewSlice'
import { usePreviewItemsUpdate } from '../photo-preview/usePreviewItemsUpdate'
import { MemoriesComponent } from "../MemoriesComponent"
import { photoPreviewFlashbackActions } from "../photo-preview-flashback/photoPreviewFlashbackSlice"
import { Tab } from "../../../ui/Tabs"
import { useAppDispatch } from "../../../store"
import { FilterSelect } from "../../../ui/FilterSelect"
import { appActions } from "../../../store/app"
import { DATES_FORMATS } from "../../../constants/datesFormats"

import {
  getDateFormatSelector,
  getFlashbackAlbumsSelector,
  getSortFormatSelector,
  getTimelineFilterSelector,
  getTimelineItemsByDateSelector,
  getTimelineItemsSelector,
  getTimelineSelectedItemsCountSelector,
  getTimelineSelectedItemsIdsSelector,
  getTimelineTotalItemsCountSelector
} from './selectors'
import {
  loadJustUploadedTimelineItemsThunk,
  loadMoreTimelineItemsThunk,
  SORT_FORMATS,
  TIMELINE_FILTERS,
  timelineActions
} from './timelineSlice'
import { EmptyTimelinePlaceholder } from './EmptyTimelinePlaceholder'
import { TimelineTabs } from "./TimelineTabs"

interface TimelineProps {
    type: SDK_TYPES,
    className?: string,
    haveCloudButtons?: boolean,
    withDisabledPreview?: boolean,
    withDisabledPhotoCard?: boolean,
    withMemories?: boolean,
    withFilters?: boolean,
    withControls?: boolean
}

export const Timeline: React.FC<TimelineProps> = ({
  className = '',
  haveCloudButtons = false,
  type,
  withDisabledPreview,
  withDisabledPhotoCard,
  withMemories,
  withFilters,
  withControls = true
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const dateFormat = getDateFormatSelector()
  const filter = getTimelineFilterSelector()
  const items = getTimelineItemsSelector()
  const itemsByDate = getTimelineItemsByDateSelector(dateFormat)
  const selectedItemsIds = getTimelineSelectedItemsIdsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const totalItemsCount = getTimelineTotalItemsCountSelector()
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()
  const smartAlbums = getFlashbackAlbumsSelector()
  const sortFormat = getSortFormatSelector()

  const tabsRef = useRef(null)
  const scrollRef = useRef(null)
  const [fixedTabs, setFixedTabs] = useState(false)
  const [lastScrollTopFix, setLastScrollTopFix] = useState(0)

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const filterOptions = [
    { label: t('a_photos_searchAllItems'), value: TIMELINE_FILTERS.all, icon: 'picture_polaroid_landscape' },
    { label: t('a_photos_searchPhotos'), value: TIMELINE_FILTERS.photos, icon: 'image_2' },
    { label: t('a_photos_searchVideos'), value: TIMELINE_FILTERS.videos, icon: 'video_2' },
    { label: t('a_nav_favorites'), value: TIMELINE_FILTERS.favorites, icon: 'favorite_border' }
  ]

  const sortOptions = [
    {
      label: t('a_common_sortDay'),
      value: DATES_FORMATS.timelineDay,
      icon: 'sort_day'
    },
    {
      label: t('a_common_sortMonth'),
      value: DATES_FORMATS.timelineMonth,
      icon: 'sort_month'
    },
    {
      label: t('a_common_sortYear'),
      value: DATES_FORMATS.timelineYear,
      icon: 'sort_year'
    },
  ]

  const loadMore = () => {
    dispatch(loadMoreTimelineItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items.length])

  usePreviewItemsUpdate(items, totalItemsCount)

  useUploadingFinishDetection(() => dispatch(loadJustUploadedTimelineItemsThunk()))

  const handleItemSelection = useCallback((id) => {
    dispatch(timelineActions.selectItem(id))
  }, [])

  const handleWholeGroupSelection = useCallback((items) => {
    let ids = items.map(item => item.id)

    if (withDisabledPhotoCard) {
      const arr = items.filter(item => !item.favorite)
      ids = arr.map(item => item.id)
      items = [...arr]
    }

    if (items.some(item => !selectedItemsIds.includes(item.id))) {
      dispatch(timelineActions.selectAllItemsInGroup(ids))
    } else {
      dispatch(timelineActions.unselectAllItemsInGroup(ids))
    }
  }, [itemsByDate])

  const handleOpenPreview = useCallback((id) => {
    analyticsService.push(ANALYTICS_EVENTS.WEB_PHOTO_VIDEO_CLICK)

    dispatch(openPhotoPreviewThunk({
      items,
      totalItemsCount,
      currentItemId: id,
      sdkType: type,
      type: PREVIEW_TYPES.TIMELINE,
      loadMore
    }))
  }, [items, totalItemsCount])

  const handleUploadFiles = (acceptedFiles: FileList) => {
    if (acceptedFiles.length) {
      if (filter === TIMELINE_FILTERS.favorites) {
        dispatch(uploadFavoriteItemsThunk({ files: acceptedFiles, type }))
      } else {
        dispatch(uploadTimelineItemsThunk({ files: acceptedFiles, type }))
      }
    }

    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_UPLOAD_CLICK)
    }

    if (type === SDK_TYPES.FAMILY) {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_PHOTO_UPLOAD_CLICK)
    }
  }

  const handleAddFavorites = () => {
    if (type === SDK_TYPES.DEFAULT) {
      navigate('/photos/favorites/add')
    } else {
      navigate('/family/photos/favorites/add')
    }

    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
  }

  const switchGridClass = (sortFormat: string) => {
    switch (sortFormat) {
    case SORT_FORMATS.sortFormat.day:
      return SORT_FORMATS.sortGridClass.day
    case SORT_FORMATS.sortFormat.month:
      return SORT_FORMATS.sortGridClass.month
    case SORT_FORMATS.sortFormat.year:
      return SORT_FORMATS.sortGridClass.year
    default:
      return SORT_FORMATS.sortGridClass.day
    }
  }

  const handleChangeTab = (tab: Tab) => {
    dispatch(timelineActions.setDateFormat(tab.value))
    dispatch(timelineActions.setSortFormat(tab.sort))

    switch (tab.value) {
    case DATES_FORMATS.timelineYear:
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_YEAR_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_YEAR_CLICK)
      return
    case DATES_FORMATS.timelineMonth:
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_MONTH_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_MONTH_CLICK)
      return
    default:
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_DAY_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_DAY_CLICK)
    }
  }

  const handleFilterChange = (option) => {
    dispatch(timelineActions.setFilter(option.value))
  }

  const handleSortChange = (option) => {
    dispatch(timelineActions.setDateFormat(option.value))

    switch (option.value) {
    case DATES_FORMATS.timelineYear:
      dispatch(timelineActions.setSortFormat(SORT_FORMATS.sortFormat.year))
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_YEAR_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_YEAR_CLICK)
      return
    case DATES_FORMATS.timelineMonth:
      dispatch(timelineActions.setSortFormat(SORT_FORMATS.sortFormat.month))
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_MONTH_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_MONTH_CLICK)
      return
    default:
      dispatch(timelineActions.setSortFormat(SORT_FORMATS.sortFormat.day))
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_DAY_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_DAY_CLICK)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const prevElement = tabsRef.current && tabsRef.current.previousElementSibling
      if(scrollRef && tabsRef && prevElement) {

        if(!!prevElement) {
          if(fixedTabs){
            setFixedTabs(scrollRef.current.scrollTop >= lastScrollTopFix)
            setLastScrollTopFix(0)
          } else {
            setFixedTabs(scrollRef.current.scrollTop >= tabsRef.current.offsetTop)
            setLastScrollTopFix(scrollRef.current.scrollTop)
          }
        } else {
          setFixedTabs(true)
        }
      }
    }

    const scrollElement = scrollRef.current
    !!scrollElement && scrollElement.addEventListener('scroll', handleScroll)
    return () => {
      !!scrollElement && scrollElement.removeEventListener('scroll', handleScroll)
    }
  }, [scrollRef, tabsRef, fixedTabs])

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFiles }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: false
    }))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
      dispatch(photoPreviewActions.resetState())
      dispatch(photoPreviewFlashbackActions.resetState())
    }
  }, [])

  if (!items.length) {
    return (
      <TimelineBox>
        {withControls && (
          <SControls>
            <STimelineTabs activeTab={dateFormat}
              onChangeTab={handleChangeTab}
            />

            <SFilterSelect options={sortOptions}
              value={sortOptions.find(option => option.value === dateFormat)}
              onSelect={handleSortChange}
            />

            {withFilters && (
              <FilterSelect options={filterOptions}
                value={filterOptions.find(option => option.value === filter)}
                onSelect={handleFilterChange}
              />
            )}
          </SControls>
        )
        }

        <EmptyTimelinePlaceholder
          texts={{
            title: filter === TIMELINE_FILTERS.favorites ? t('l_favorites_noTitle') : t('l_photos_emptyPageTitle'),
            description: filter === TIMELINE_FILTERS.favorites ? t('l_favorites_noMessage') : t('l_photos_emptyPageMessage') ,
            uploadBtn: filter === TIMELINE_FILTERS.favorites ? t('a_common_addToFavorites') : t('a_common_uploadPhotos')
          }}
          onUploadFiles={filter === TIMELINE_FILTERS.favorites ? handleAddFavorites : handleUploadFiles}
          withoutUploading={filter === TIMELINE_FILTERS.favorites}
        />

        <DropzoneArea
          visible={isDropzoneAreaVisible}
        />
      </TimelineBox>
    )
  }

  return (
    <STimelineScroll haveCloudButtons={haveCloudButtons} ref={scrollRef}>
      <TimelineBox
        className={classNames(className, { 'with-overflow': isDropzoneAreaVisible })}
      >
        {withMemories && (
          <MemoriesComponent
            smartAlbums={smartAlbums}
          />
        )}

        {withControls && (
          <SControls className={classNames('top-to-family')}
            position={fixedTabs ? 'fixed' : ''}
            ref={tabsRef}
          >
            <STimelineTabs activeTab={dateFormat}
              onChangeTab={handleChangeTab}
            />

            <SFilterSelect options={sortOptions}
              value={sortOptions.find(option => option.value === dateFormat)}
              onSelect={handleSortChange}
            />

            {withFilters && (
              <FilterSelect options={filterOptions}
                value={filterOptions.find(option => option.value === filter)}
                onSelect={handleFilterChange}
              />
            )}
          </SControls>
        )}

        <TimelineItemsBox marginTop={fixedTabs ? '80px' : '0'}>
          {
            itemsByDate.map(itemsGroup => {
              const isTitleChecked = withDisabledPhotoCard
                ? !itemsGroup.items.filter(item => !item.favorite).some(item => !selectedItemsIds.includes(item.id))
                : !itemsGroup.items.some(item => !selectedItemsIds.includes(item.id))
              const isTitleDisabled = itemsGroup.items.some(item => !item.favorite)

              return (
                <React.Fragment key={itemsGroup.date}>
                  <PhotosGroupTitleBox
                    title={itemsGroup.date}
                    checked={isTitleChecked}
                    onClick={() => handleWholeGroupSelection(itemsGroup.items)}
                    checkIconVisibleByDefault={selectedItemsCount > 0}
                    isDisabled={withDisabledPhotoCard ? !isTitleDisabled : withDisabledPhotoCard}
                  />

                  <PhotosGrid className={switchGridClass(sortFormat)}>
                    {itemsGroup.items.map(item => (
                      <MemoizedPhotoCard
                        key={item.id}
                        id={item.id}
                        imgUrl={item._links?.image_middle?.href}
                        isSelected={selectedItemsIds.includes(item.id)}
                        onSelect={handleItemSelection}
                        onClick={withDisabledPreview ? handleItemSelection : handleOpenPreview}
                        isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
                        type={item.type}
                        isFavorite={item.favorite}
                        isDisabled={withDisabledPhotoCard && item.favorite}
                      />
                    ))}
                  </PhotosGrid>
                </React.Fragment>
              )
            }
            )
          }

          {items.length < totalItemsCount && (
            <div ref={loaderBoxRef}>
              <StyledPageSpinner />
            </div>
          )}
        </TimelineItemsBox>

        <DropzoneArea
          visible={isDropzoneAreaVisible}
        />
      </TimelineBox>
    </STimelineScroll>
  )
}

const STimelineScroll = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  margin-right: -28px;
  padding-right: 20px;
  position: relative;
  height: calc(100% - ${(props:any)=> props.haveCloudButtons ? '85px' : '44px'});

  @media screen and  (min-width: 1440px) {
    height: calc(100% - 82px);
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    margin-right: unset;
  }
  
  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;


    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

const MemoizedPhotoCard = React.memo(PhotoCard)

const PhotosGroupTitleBox = styled(PhotosGroupTitle)`
  text-transform: capitalize;
  padding: 10px 0 18px;
`

const TimelineBox = styled.div`
  width: 100%;
  position: relative;

  &.with-overflow {
    max-height: calc(100vh - 120px);
    overflow: hidden;
  }
`

const TimelineItemsBox = styled.div`
  width: 100%;
  margin-top: ${(props:any) => props.marginTop};
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`

const SControls = styled.div`
  display: flex;
  margin-bottom: 8px;
  padding-top: 12px;
  padding-bottom: 0;
  height: 80px;
  position: ${(props:any) => props.position};
  top: 80px;
  width: ${(props:any) => props.position ?  'calc(100% - 532px)' : '100%'};
  z-index: 10;
  background: var(--background-primary);
  align-items: center;
  
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    width: 100%;
  }
`

const SFilterSelect = styled(FilterSelect)`
  margin-right: 8px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const STimelineTabs = styled(TimelineTabs)`
  display: none;
    
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: flex;
  }
`
