import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'
import { PHOTOS_COUNT_PER_PAGE } from 'constants/pagination'

import { IAlbumSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { getAlbumsSdkByType, getCurrentAlbumItemsPaginator, initAlbumItemsPaginator } from 'sdk/albums'
import { RootState } from 'store'
import { getPhotosWS } from 'sdk/photo'
import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { getFamilyTimelineSdk, getPhotoTimelineSdk } from 'sdk/timeline'
import { AlbumType } from '@cloudike/web_photos'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import i18n from 'i18n'
import { getErrorData } from 'utils/getErrorData'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { downloadItemByLink, getErrorByFieldName } from 'utils/utils'
import { t } from "i18next"
import _ from "lodash"

import { sharingActions } from '../../sharing/sharingSlice'
import { photoPreviewActions } from "../../photo/photo-preview/photoPreviewSlice"
import {
  photoPreviewDuplicateActions,
  photoPreviewDuplicateSelectors
} from "../../photo/photo-preview-duplicate/photoPreviewDuplicateSlice"
import { goToSubscriptionsPage } from "../../../utils/subscriptions"
import { DATES_FORMATS } from "../../../constants/datesFormats"
import { SORT_FORMATS } from "../../photo/timeline/timelineSlice"
import { TOTAL_COUNT_HEADER } from "../../../constants/headers"
import { API_UTILS } from "../../../constants/apiUtils"

interface State {
    albumData: IAlbumSchema | null,
    itemsLoadingStatus: LOADING_STATUSES,
    albumDataLoadingStatus: LOADING_STATUSES,
    loadingMoreStatus: LOADING_STATUSES,
    isLoading: boolean,
    error: string,
    type: SDK_TYPES,
    albumId: string | null,
    isAlbumNameEditing: boolean,
    totalItemsCount: number,
    selectedItemsIds: string[],
    dateFormat: typeof DATES_FORMATS.timelineDay,
    sortFormat: typeof SORT_FORMATS.sortFormat.day
}

export type IExtendedItemSchema = { selected?: boolean } & IItemSchema

const adapter = createEntityAdapter<IExtendedItemSchema>()

export const albumsSelectors = adapter.getSelectors()

const getCurrentAlbumsType = (state: RootState) => state.album.type
const getCurrentAlbumId = (state: RootState) => state.album.albumId

const sortByDate = (items: IItemSchema[]) => items.sort((a, b) => b.created_original - a.created_original)

export const subscribeAlbumToWSThunk = createAsyncThunk(
  'album/connectTimelineToWSThunk',
  async function (_, { dispatch, getState }) {
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_OPERATION_DONE, ({ action, output }) => {
      if (action === 'add_items') {
        dispatch(loadJustUploadedAlbumItemsThunk())
      }

      if (action === 'delete_items') {
        const state = getState() as RootState
        const allItemsLength = albumsSelectors.selectIds(state.album).length

        if (output.length === allItemsLength) {
          dispatch(actions.setItemsLoadingStatus(LOADING_STATUSES.LOADING))
          dispatch(loadAlbumItemsFirstTimeThunk())
        } else {
          dispatch(actions.removeItems(output.map(item => item.detail.item_id)))
          dispatch(actions.decreaseTotalCount(output.length))
        }

        dispatch(actions.unselectAll())

        hideGlobalProgressLoader()
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEM_UPDATED, ({ output }) => {
      const state = getState() as RootState
      const currentEntities = albumsSelectors.selectEntities(state.album)

      dispatch(photoPreviewDuplicateActions.updateItem(output))

      if (currentEntities[output.id]) {
        dispatch(actions.updateItem(output))
        dispatch(photoPreviewActions.updateItem(output))
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE, ({ action, output }) => {
      if (action === 'delete_items') {
        const state = getState() as RootState
        const currentEntities = albumsSelectors.selectEntities(state.album)

        if (currentEntities[output[0].detail.item_id]) {
          dispatch(actions.removeItems(output.map(item => item.detail.item_id)))
          dispatch(actions.decreaseTotalCount(output.length))
        }

      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEMS_MOVED_INTO_TRASH, ({ output }) => {
      const state = getState() as RootState
      const array = photoPreviewDuplicateSelectors.selectAll(state.photoPreviewDuplicate)
      const currentItem = array.find(item => item.id === output.item_id)

      if(state.photoPreviewDuplicate.duplicateMode && !!currentItem) {
        if (currentItem.isOrigin) {
          dispatch(photoPreviewDuplicateActions.resetState())
          dispatch(photoPreviewActions.resetState())
        } else {
          dispatch(photoPreviewDuplicateActions.deleteItem(output.item_id))
        }
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED, (albumEventData) => {
      try{
        const state = getState() as RootState
        const { output, group } = albumEventData
        const { type, albumId } = state.album
        const groupSdkTypes = {
          family: [SDK_TYPES.FAMILY],
          photos: [SDK_TYPES.DEFAULT]
        }
        if (albumId === output.id && type && groupSdkTypes[group].includes(type)) {
          dispatch(actions.updateAlbumData(output))
        }
      } catch (e){
        console.log(`${WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED} event handler error`, e)
      }
    })
  }
)

export const unsubscribeAlbumFromWSThunk = createAsyncThunk(
  'album/unsubscribeTimelineFromWSThunk',
  async function () {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_OPERATION_DONE)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ITEM_UPDATED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ALBUM_CHANGED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_ITEMS_MOVED_INTO_TRASH)
  }
)

export const fetchAlbumDataThunk = createAsyncThunk(
  'album/fetchAlbumDataThunk',
  async function (_, { getState }) {
    const state = getState() as RootState
    const type = getCurrentAlbumsType(state)
    const albumId = getCurrentAlbumId(state)
    const albumsSdk = getAlbumsSdkByType(type)

    const response = await albumsSdk.getAlbum(albumId, {},{ preview_jwt: true })

    return response.data
  }
)

export const loadAlbumItemsFirstTimeThunk = createAsyncThunk(
  'album/loadAlbumItemsFirstTimeThunk',
  async function (_, { getState }) {
    const state = getState() as RootState
    const type = getCurrentAlbumsType(state)
    const albumId = getCurrentAlbumId(state)

    const paginator = initAlbumItemsPaginator(albumId, type, PHOTOS_COUNT_PER_PAGE, { total_count: true })

    const response = await paginator.next()

    return {
      items: response.data._embedded.items,
      totalCount: parseInt(response.headers[TOTAL_COUNT_HEADER]) || 0
    }
  }
)

export const loadJustUploadedAlbumItemsThunk = createAsyncThunk(
  'album/loadJustUploadedAlbumItemsThunk',
  async function (_, { getState, dispatch }) {
    const state = getState() as RootState
    const type = getCurrentAlbumsType(state)
    const albumId = getCurrentAlbumId(state)
    const albumsSdk = getAlbumsSdkByType(type)

    const currentItems = albumsSelectors.selectAll(state.album)
    const currentItemsIds = currentItems.map(item => item.id)

    const limit = Math.max(currentItems.length, PHOTOS_COUNT_PER_PAGE)

    const response = await albumsSdk.getAlbumItems(albumId, { offset: 0, limit, total_count: true })

    const itemsForInsert = response.data._embedded.items.filter(item => !currentItemsIds.includes(item.id))

    dispatch(actions.setAllItems(sortByDate([...itemsForInsert, ...currentItems])))
    dispatch(actions.setTotalCount(response.headers[TOTAL_COUNT_HEADER]))
    dispatch(actions.setItemsLoadingStatus(LOADING_STATUSES.SUCCEEDED))
  }
)

export const loadMoreAlbumItemsThunk = createAsyncThunk(
  'album/loadMoreAlbumItemsThunk',
  async function () {
    const paginator = getCurrentAlbumItemsPaginator()

    const response = await paginator.next()

    return response.data._embedded.items
  }
)

export const renameAlbumThunk = createAsyncThunk(
  'album/renameAlbumThunk',
  async function (name: string, { getState, dispatch }) {
    try {
      const state = getState() as RootState
      const type = getCurrentAlbumsType(state)
      const albumId = getCurrentAlbumId(state)
      const albumsSdk = getAlbumsSdkByType(type)

      dispatch(actions.updateAlbumData({ description: name }))

      showGlobalProgressLoader()

      await albumsSdk.renameAlbum(albumId, name)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumRenamed')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const removeAlbumThunk = createAsyncThunk(
  'album/removeAlbumThunk',
  async function (callback: () => void, { getState }) {
    try {
      const state = getState() as RootState
      const type = getCurrentAlbumsType(state)
      const albumId = getCurrentAlbumId(state)
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      await albumsSdk.removeAlbum(albumId)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumDeleted', { number: 1 })
      })

      callback()
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const downloadAlbumThunk = createAsyncThunk(
  'album/downloadAlbumThunk',
  async function (_, { getState }) {
    try {
      const state = getState() as RootState
      const type = getCurrentAlbumsType(state)
      const albumId = getCurrentAlbumId(state)
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      const response = await albumsSdk.createAlbumZipStream(albumId)

      window.location.href = response
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const addItemsToAlbumThunk = createAsyncThunk(
  'album/addItemsToAlbumThunk',
  async function ({ items, callback }: { items: IItemSchema[], callback: () => void }, { getState }) {
    const state = getState() as RootState

    try {
      const type = getCurrentAlbumsType(state)
      const albumId = getCurrentAlbumId(state)
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      await albumsSdk.addItemsToAlbum(albumId, items)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumUpdated', { number: 1 })
      })
      hideGlobalProgressLoader()

    }
    catch (error) {
      const isOperationsHasError = (operation: any) => operation.status >= 400
      const details = getErrorByFieldName(error, 'details')
      const operationsError = details.operations.find(isOperationsHasError)
      if (!operationsError) return
      const errorData = getErrorData({
        data: { ...operationsError },
        action: API_UTILS.ACTIONS_TYPES.COPY,
        type: API_UTILS.ITEM_TYPE.PHOTO,
        isFamilyOwner: state.user.userData.is_owner_family
      })
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...errorData
      })
    }
    finally {
      callback()
      hideGlobalProgressLoader()
    }
  }
)

export const copyAlbumItemsToFamilyCloudThunk = createAsyncThunk(
  'album/copyAlbumItemsToFamilyThunk',
  async function (items: IItemSchema[], { dispatch, getState }) {
    const state = getState() as RootState
    try {
      const familyTimelineSdk = getFamilyTimelineSdk()

      showGlobalProgressLoader()

      await familyTimelineSdk.addItemsToTimeline(items)
      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_copiedToFamilyCloud')
      })
    } catch (error) {
      const isOperationsHasError = (operation: any) => operation.status >= 400
      const details = getErrorByFieldName(error, 'details')
      const operationsError = details.operations.find(isOperationsHasError)
      if (!operationsError) return
      const errorData = getErrorData({
        data: { ...operationsError },
        action: API_UTILS.ACTIONS_TYPES.COPY_TO_FAMILY,
        type: API_UTILS.ITEM_TYPE.PHOTO,
        isFamilyOwner: state.user.userData.is_owner_family
      })
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...errorData
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const copyAlbumItemsToPersonalCloudThunk = createAsyncThunk(
  'album/copyAlbumItemsToFamilyThunk',
  async function (items: IItemSchema[], { dispatch }) {
    try {
      const timelineSdk = getPhotoTimelineSdk()

      showGlobalProgressLoader()

      await timelineSdk.addItemsToTimeline(items)
      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_copiedToPersonalCloud')
      })
    } catch (error) {
      const isOperationsHasError = (operation: any) => operation.status >= 400
      const details = getErrorByFieldName(error, 'details')
      const operationsError = details.operations.find(isOperationsHasError)
      if (!operationsError) return
      const errorData = getErrorData({
        data: { ...operationsError },
        action: API_UTILS.ACTIONS_TYPES.COPY,
        type: API_UTILS.ITEM_TYPE.PHOTO,
      })
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...errorData
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const createAndShareAlbumThunk = createAsyncThunk(
  'album/createAlbumAndShareThunk',
  async function ({ type, items }: { type: SDK_TYPES, items: IItemSchema[] }, { dispatch }) {
    const album = {
      type: AlbumType.TMP,
      description: ''
    }

    try {
      showGlobalProgressLoader()

      const albumSdk = getAlbumsSdkByType(type)

      const response = await albumSdk.createAlbumWithItems(album, items)

      dispatch(sharingActions.setSharingAlbumConfig({
        sdkType: type,
        sharedAlbumType: AlbumType.TMP,
        isNewAlbum: true,
        albumElementsCount: items.length
      }))
      dispatch(sharingActions.setSharedItemData(response.data))
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const createNewAlbumWithItemsThunk = createAsyncThunk(
  'album/createNewAlbumWithItemsThunk',
  async function ({ items, callback, type }: { items: IItemSchema[], type: SDK_TYPES, callback: (id: any) => void }) {
    try {
      showGlobalProgressLoader()
      const albumSdk = getAlbumsSdkByType(type)

      const albumData = {
        type: AlbumType.SIMPLE,
        description: ''
      }

      const response = await albumSdk.createAlbumWithItems(albumData, items)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumCreated')
      })

      callback(response.data.id)
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const createNewEmptyAlbumThunk = createAsyncThunk(
  'album/createNewEmptyAlbumThunk',
  async function ({ callback, type, name }: { type: SDK_TYPES, callback: (id: any) => void, name: string }, { dispatch }) {
    try {
      showGlobalProgressLoader()
      dispatch(actions.setLoading(true))
      const albumSdk = getAlbumsSdkByType(type)

      const albumData = {
        type: AlbumType.SIMPLE,
        description: name
      }

      const response = await albumSdk.createAlbum(albumData)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_albumCreated')
      })

      callback(response.data.id)
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.setLoading(false))
    }
  }
)

export const downloadAlbumItemsThunk = createAsyncThunk(
  'album/downloadAlbumItemsThunk',
  async function ({ items, type }: { items: IItemSchema[], type: SDK_TYPES }, { dispatch } ) {
    try {
      showGlobalProgressLoader()
      const albumSdk = getAlbumsSdkByType(type)
      let href

      if (items.length === 1) {
        const {
          _links: {
            content: { href: contentLink }
          }
        } = items[0]

        href = await downloadItemByLink(contentLink)
      } else {
        href = await albumSdk.createAlbumItemsZipStream(items)
      }

      window.location.href = href
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
      dispatch(actions.unselectAll())
    }
  }
)

export const removeAlbumItemsThunk = createAsyncThunk(
  'album/removeAlbumItemsThunk',
  async function ({ items, type }: { items: IItemSchema[], type: SDK_TYPES }, { getState, dispatch }) {
    try {
      const state = getState() as RootState
      const albumId = getCurrentAlbumId(state)
      const albumsSdk = getAlbumsSdkByType(type)

      showGlobalProgressLoader()

      await albumsSdk.removeAlbumItems(albumId, items)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: i18n.t('l_notification_itemsDeletedforWeb', { number: items.length })
      })

      dispatch(actions.unselectAll())
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const albumSlice = createSlice({
  name: 'album',
  initialState: adapter.getInitialState<State>({
    itemsLoadingStatus: LOADING_STATUSES.LOADING,
    albumDataLoadingStatus: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    isLoading: false,
    totalItemsCount: 0,
    error: '',
    type: SDK_TYPES.DEFAULT,
    albumId: null,
    albumData: null,
    isAlbumNameEditing: false,
    selectedItemsIds: [],
    dateFormat: DATES_FORMATS.timelineDay,
    sortFormat: SORT_FORMATS.sortFormat.day
  }),
  reducers: {
    setCurrentAlbumId: (state, action) => {
      state.albumId = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setItemsLoadingStatus: (state, action) => {
      state.itemsLoadingStatus = action.payload
    },
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setDateFormat: (state, action) => {
      state.dateFormat = action.payload
    },
    setSortFormat: (state, action) => {
      state.sortFormat = action.payload
    },
    selectItem: (state, action) => {
      const id = action.payload
      const indexOfItemId = state.selectedItemsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedItemsIds = [...state.selectedItemsIds, id]
      } else {
        state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
      }
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    unselectAll: (state) => {
      state.selectedItemsIds = []
    },
    removeItems: (state, action) => {
      adapter.removeMany(state, action.payload)
    },
    setTotalCount: (state, action) => {
      state.totalItemsCount = action.payload
    },
    setCurrentAlbumsType: (state, action) => {
      state.type = action.payload
    },
    toggleAlbumNameEditStatus: (state, action) => {
      state.isAlbumNameEditing = action.payload
    },
    selectAllItemsInGroup: (state, action) => {
      state.selectedItemsIds = _.uniq([...state.selectedItemsIds, ...action.payload])
    },
    unselectAllItemsInGroup: (state, action) => {
      state.selectedItemsIds = _.difference(state.selectedItemsIds, action.payload)
    },
    setAlbumData: (state, action) => {
      state.albumData = action.payload
    },
    updateAlbumData: (state, action) => {
      state.albumData = { ...state.albumData, ...action.payload }
    },
    decreaseTotalCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = state.totalItemsCount - action.payload
    },
    resetState: (state) => {
      state.itemsLoadingStatus = LOADING_STATUSES.LOADING
      state.albumDataLoadingStatus = LOADING_STATUSES.LOADING
      state.albumId = null
      state.albumData = null
      state.selectedItemsIds = []

      adapter.removeAll(state)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadAlbumItemsFirstTimeThunk.pending, (state) => {
        state.itemsLoadingStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadAlbumItemsFirstTimeThunk.fulfilled, (state, action) => {
        state.itemsLoadingStatus = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
        state.totalItemsCount = action.payload.totalCount
      })
      .addCase(loadAlbumItemsFirstTimeThunk.rejected, (state, action) => {
        state.itemsLoadingStatus = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(loadMoreAlbumItemsThunk.pending, (state) => {
        state.loadingMoreStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadMoreAlbumItemsThunk.fulfilled, (state, action) => {
        state.itemsLoadingStatus = LOADING_STATUSES.SUCCEEDED
        adapter.addMany(state, action.payload)
      })
      .addCase(fetchAlbumDataThunk.pending, (state) => {
        state.albumDataLoadingStatus = LOADING_STATUSES.LOADING
      })
      .addCase(fetchAlbumDataThunk.fulfilled, (state, action) => {
        state.albumDataLoadingStatus = LOADING_STATUSES.SUCCEEDED
        state.albumData = action.payload
      })
  },
})

const {
  reducer, actions
} = albumSlice

export { reducer as albumReducer, actions as albumActions }
