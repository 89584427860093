import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getFilesSdk } from 'sdk/files'
import { OrderType } from '@cloudike/web_ui_components'
import { CopyFileExtraParamsType, IDestinationParams, IFsNodeSchema, NodeTypes } from '@cloudike/web_files'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { t } from 'i18next'
import { RootState } from 'store'
import _ from "lodash"

import { cacheService, filesActions } from '../filesSlice'
import { getNewFileName, getNewFolderName, isFileExist, isFolderExist } from '../filesUtils'
import { goToSubscriptionsPage } from "../../../utils/subscriptions"
import { filesApi } from "../../../api/filesApi"
import { request } from "../../../api/request"

export enum SortColumns {
  NAME = 'name',
  MODIFIED = 'updated',
  SIZE = 'file_info.size',
}

const adapter = createEntityAdapter<IFsNodeSchema>()

export const copyMoveModalSelectors = adapter.getSelectors()

const initialState = {
  status: LOADING_STATUSES.IDLE,
  error: null,
  sort: { by: SortColumns.NAME, direction: OrderType.ASK },
  selectedItemsIds: [],
  renamingItemId: null,
  currentFolderId: '',
  breadcrumbs: [{ name: '', id: '' }]
}

export const fetchCopyMoveNodesThunk = createAsyncThunk(
  'copyMoveModal/fetchCopyMoveNodesThunk',
  async ({ parentId = '' }: { parentId?: string }) => {
    const sdk = getFilesSdk()

    const response = await sdk.folderOperationsService.getAllFolderContent({ parent_id: parentId, preview: true, preview_jwt: true }, 500)

    const nodes = response.filter(node => !node.is_trashed)

    return nodes
  }
)

export const createCopyMoveFolderThunk = createAsyncThunk(
  'copyMoveModal/createCopyMoveFolderThunk',
  async ({ name, parentId, callback }: { name: string, parentId: string, callback: () => void }, { dispatch, getState }) => {
    const state = getState() as RootState

    showGlobalProgressLoader()

    try {
      const sdk = getFilesSdk()

      if (isFolderExist(copyMoveModalSelectors.selectAll(state.copyMoveModal), name)) {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_folderNameError'),
          message: t('l_notification_createFolderError')
        })

        return
      }

      const response = await sdk.folderOperationsService.createNewFolder(name, parentId, { unique_name: true })

      dispatch(actions.addFolder(response.data))
      callback()
    } catch (error) {

    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const copyItemsThunk = createAsyncThunk(
  'copyMoveModal/copyItemsThunk',
  async ({ items, parentId }: { items: IFsNodeSchema[], parentId: string }, { getState }) => {
    showGlobalProgressLoader()
    const state = getState() as RootState

    const currentItems = copyMoveModalSelectors.selectAll(state.copyMoveModal)

    try {
      const sdk = getFilesSdk()

      await Promise.all(items.map(async item => {
        if (item.type === NodeTypes.DIR) {
          let newItemName


          if (isFolderExist(currentItems, item.name)) {
            newItemName = getNewFolderName(currentItems, item.name)
          }

          let source = item?._links?.self?.href

          if (!source) {
            const { _links: { node } } = await filesApi.getFsRoot(state.user.userData.id)
            const urlTemplate = _.template(node.href, { interpolate: /\{(.+?)\}/g })
            const url = urlTemplate({ node_id: item.id })

            const data = await request('GET', url, {}, { host: null })

            source = data._links.self.href
          }

          const params: IDestinationParams = {
            source,
            destination: {
              parent_id: parentId
            }
          }

          if (newItemName) {
            params.destination.name = newItemName
          }

          await sdk.folderOperationsService.copyDir(params)
        }

        if (item.type === NodeTypes.FILE) {
          let newItemName

          if (isFileExist(currentItems, item.name)) {
            newItemName = getNewFileName(currentItems, item.name)
          }

          const params: CopyFileExtraParamsType = {
            name: newItemName
          }

          await sdk.folderOperationsService.copyFileByFileSource(item.id, parentId, newItemName ? params : undefined)
        }
      }))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsCopied', { number: items.length })
      })
    } catch (error) {
      if (error?.cause?.cause?.code === 'SizeQuotaExceeded') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          isPermanent: true,
          message: t('l_notification_copyFilesError'),
          title: t('l_notification_spaceError'),
          callback: () => {
            goToSubscriptionsPage()
          }
        })
      }
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const copyItemsFromSharedFolderThunk = createAsyncThunk(
  'copyMoveModal/copyItemsFromSharedFolderThunk',
  async ({ items, parentId, shareId }: { items: IFsNodeSchema[], parentId: string, shareId: string }, { getState }) => {
    showGlobalProgressLoader()
    const state = getState() as RootState

    const currentItems = copyMoveModalSelectors.selectAll(state.copyMoveModal)

    try {
      const sdk = getFilesSdk()

      await Promise.all(items.map(async item => {
        if (item.type === NodeTypes.DIR) {
          let newItemName

          if (isFolderExist(currentItems, item.name)) {
            newItemName = getNewFolderName(currentItems, item.name)
          }

          if (item.id === shareId) {
            await sdk.publicLinksService.copySharedWithMeRootDirToPersonal(parentId, shareId, newItemName ? { name: newItemName } : undefined)
          } else {
            await sdk.publicLinksService.copySharedWithMeDirToPersonal(item.id, parentId, shareId, newItemName ? { name: newItemName } : undefined)
          }
        }

        if (item.type === NodeTypes.FILE) {
          let newItemName

          if (isFileExist(currentItems, item.name)) {
            newItemName = getNewFileName(currentItems, item.name)
          }

          await sdk.publicLinksService.copySharedWithMeByShareIdToPersonal(item.id, parentId, shareId, newItemName ? { name: newItemName } : undefined)
        }
      }))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsCopied', { number: items.length })
      })
    } catch (error) {

    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const moveItemsThunk = createAsyncThunk(
  'copyMoveModal/moveItemsThunk',
  async ({ items, parentId }: { items: IFsNodeSchema[], parentId: string }, { dispatch, getState }) => {
    showGlobalProgressLoader()

    const state = getState() as RootState

    const currentItems = copyMoveModalSelectors.selectAll(state.copyMoveModal)

    try {
      const sdk = getFilesSdk()

      await Promise.all(items.map(async item => {
        let newItemName

        if (item.type === NodeTypes.DIR) {
          if (isFolderExist(currentItems, item.name)) {
            newItemName = getNewFolderName(currentItems, item.name)
          }
        }

        if (item.type === NodeTypes.FILE) {
          if (isFileExist(currentItems, item.name)) {
            newItemName = getNewFileName(currentItems, item.name)
          }
        }

        await sdk.folderOperationsService.moveNode(item.id, parentId, newItemName ? { name: newItemName } : undefined)
      }))

      cacheService.deleteKey(parentId)

      dispatch(filesActions.deleteItems(items.map(item => item.id)))
      dispatch(filesActions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsMoved', { number: items.length })
      })
    } catch (error) {

    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const copyMoveModalSlice = createSlice({
  name: 'copyMoveModal',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setCurrentFolderId: (state, action) => {
      state.currentFolderId = action.payload
    },
    addBreadcrumb: (state, action) => {
      state.breadcrumbs.push(action.payload)
    },
    goToBreadcrumb: (state, action) => {
      const indexOfBreadcrumb = state.breadcrumbs.findIndex(breadcrumb => breadcrumb.id === action.payload.id)

      state.breadcrumbs = state.breadcrumbs.slice(0, indexOfBreadcrumb + 1)
    },
    addFolder: (state, action) => {
      adapter.setAll(state, [action.payload, ...copyMoveModalSelectors.selectAll(state)])
    },
    resetState: (state) => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })

      adapter.removeAll(state)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCopyMoveNodesThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchCopyMoveNodesThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload)
      })
      .addCase(fetchCopyMoveNodesThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

export default copyMoveModalSlice.reducer

const {
  reducer, actions
} = copyMoveModalSlice

export { reducer as copyMoveModalReducer, actions as copyMoveModalActions }
