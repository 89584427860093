import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useState } from 'react'

import { MenuItem, MenuWithActionItems, PrimaryButton } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { DialogModal } from 'ui/DialogModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlbumType } from '@cloudike/web_photos'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { appActions } from 'store/app'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import _ from "lodash"
import { IAlbumSchema } from "@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema"

import { sharingActions } from '../../sharing/sharingSlice'
import { getUserDataSelector, getUserSettingsByPathSelector } from "../../user/selectors"
import { addSelectedToFavoritesItemsThunk, removeSelectedFromFavoritesItemsThunk } from "../../favorites/favoritesSlice"
import { openPreviewDuplicateThunk } from "../../photo/photo-preview-duplicate/photoPreviewDuplicateSlice"
import { USER_SETTINGS_PATHS } from "../../user/constants"
import { REDIRECT_TO } from "../../../constants/searchParams"
import { DEFAULT_ANALYTICS_EVENTS } from "../../common/analytics/events"
import { analyticsService } from "../../common/analytics/analyticsService"
import { MobileToolbarCounter } from "../../common/mobile-toolbar-counter/MobileToolbarCounter"

import {
  getAlbumDataSelector,
  getAlbumItemsSelector,
  getAlbumSelectedItemsSelector,
  getAlbumTotalItemsCountSelector
} from './selectors'
import {
  albumActions,
  copyAlbumItemsToFamilyCloudThunk,
  copyAlbumItemsToPersonalCloudThunk,
  createAndShareAlbumThunk,
  createNewAlbumWithItemsThunk,
  downloadAlbumItemsThunk,
  downloadAlbumThunk,
  removeAlbumItemsThunk,
  removeAlbumThunk
} from './albumSlice'

interface AlbumToolbarProps {
  className?: string,
  type: SDK_TYPES,
  isPlace?: boolean,
  isSeason?: boolean,
  isPerson?: boolean,
  isCalendar?: boolean,
  hideWithoutSelection?: boolean
}

export const AlbumToolbar: React.FC<AlbumToolbarProps> = ({
  className = '',
  type,
  isPlace = false,
  isSeason = false,
  isPerson = false,
  isCalendar = false,
  hideWithoutSelection
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const albumData = getAlbumDataSelector()
  const selectedItems = getAlbumSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  const items = getAlbumItemsSelector()
  const userData = getUserDataSelector()
  const isEnabledPhotoDuplicates = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.PHOTOS_DUPLICATES).enabled

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [removingItemsConfirmationModalOpened, toggleRemovingItemsConfirmationModal] = useState(false)
  const location = useLocation()

  const isSelectedItemsHaveOnlyFavorite = () => {
    return selectedItems.some(item => !item.favorite)
  }

  const pushAddPhotoEvent = (sdkType: SDK_TYPES) => {
    const addPhotoEventData = sdkType === SDK_TYPES.FAMILY ?
      DEFAULT_ANALYTICS_EVENTS.WEB_FC_ACTIONS_ALBUM_ADD_FAMILY :
      DEFAULT_ANALYTICS_EVENTS.WEB_FC_ACTIONS_ALBUM_ADD_PERSONAL
    analyticsService.push(addPhotoEventData)
  }

  const handleAddPhoto = (sdkType: SDK_TYPES) => {
    if (!albumData) return
    const redirectToPath = location.pathname
    const familyCloudPart = 'family/photos'
    const toAlbumPart = `albums/${albumData.id}/add`
    const redirectPathParam = `${REDIRECT_TO}=${redirectToPath}`
    const personalTimelinePath = `/photos/${toAlbumPart}`

    const pathsLookup = {
      [SDK_TYPES.DEFAULT]:`/photos/albums/${albumData.id}/add?${redirectPathParam}`,
      [SDK_TYPES.FAMILY]:`/family/photos/albums/${albumData.id}/add?${redirectPathParam}`,
    }

    pushAddPhotoEvent(sdkType)

    let path = personalTimelinePath

    if(!_.isNil(redirectToPath.match(familyCloudPart))) {
      path =  sdkType ? pathsLookup[sdkType] : pathsLookup[type] || ''
    }

    if(path) {
      navigate(path)
    }

    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveAlbum = () => {
    const callback = () => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate('/photos/albums', { replace: true })
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate('/family/photos/albums', { replace: true })
      }
    }

    dispatch(removeAlbumThunk(callback))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_REMOVE_CLICK)
  }

  const handleRemoveAlbumItems = () => {
    dispatch(removeAlbumItemsThunk({ items: selectedItems, type }))
    toggleRemovingItemsConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRenameAlbum = () => {
    dispatch(albumActions.toggleAlbumNameEditStatus(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleOpenRemovingItemsConfirmationModal = () => {
    toggleRemovingItemsConfirmationModal(true)
  }

  const handleCloseRemovingItemsConfirmationModal = () => {
    toggleRemovingItemsConfirmationModal(false)
  }

  const handleShare = (albumData: IAlbumSchema | null) => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: type,
      sharedAlbumType: AlbumType.SHARED
    }))
    dispatch(sharingActions.setSharedItemData(albumData))
    pushCreateEditLinkEvent(albumData)
  }

  const handleSelectedItemsSharing = (albumData: IAlbumSchema | null) => {
    dispatch(createAndShareAlbumThunk({ type, items: selectedItems }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    pushCreateEditLinkEvent(albumData)
  }

  const pushCreateEditLinkEvent = (albumData: IAlbumSchema | null) => {
    if(!_.isNil(albumData) && albumData?.shared_hash) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_EDIT_LINK)
    } else {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_CREATE_LINK)
    }
  }

  const handleDownloadAlbum = () => {
    dispatch(downloadAlbumThunk())
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadSelectedItems = () => {
    dispatch(downloadAlbumItemsThunk({ type, items: selectedItems }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCopyToFamily = () => {
    dispatch(copyAlbumItemsToFamilyCloudThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCopyToPersonalCloud = () => {
    dispatch(copyAlbumItemsToPersonalCloudThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCreateNewAlbum = () => {
    const callback = (id: string) => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}/update`)
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}/update`)
      }
    }

    dispatch(createNewAlbumWithItemsThunk({ callback, items: selectedItems, type }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleAddToFavoritesItems = () => {
    dispatch(addSelectedToFavoritesItemsThunk({ items: selectedItems, withNotification: true, type }))
    dispatch(albumActions.unselectAll())
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveFromFavoritesItems = () => {
    dispatch(removeSelectedFromFavoritesItemsThunk({ items: selectedItems, type }))
    dispatch(albumActions.unselectAll())
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_REMOVE_FROM_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const totalItemsCount = getAlbumTotalItemsCountSelector()

  const handleDuplicate = () => {
    if (selectedItems.length === 1) {
      dispatch(openPreviewDuplicateThunk({ selectedItem: selectedItems[0], items, totalItemsCount, type }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const menuItems = (() => {
    if (!selectedItemsCount && hideWithoutSelection) {
      return []
    }

    if (!selectedItemsCount) {
      return [
        !isPerson && {
          label: albumData?.shared_hash ? t('a_common_editPublicLink') : t('a_common_createPublicLink'),
          iconName: 'link',
          onClickItem: handleShare.bind(null, albumData)
        },
        !!items.length && !isPerson && {
          label: t('a_albums_downloadAlbum'),
          iconName: 'download',
          onClickItem: handleDownloadAlbum
        },
        !isSeason && !isPerson && {
          label: t('a_albums_renameAlbum'),
          iconName: 'edit',
          onClickItem: handleRenameAlbum
        },
        !isPerson && !isCalendar && {
          label: t('a_albums_deleteAlbum'),
          iconName: 'delete',
          onClickItem: handleOpenRemovingConfirmationModal
        }
      ].filter(item => !!item)
    }

    return [
      !!userData?.family_user_id && {
        label: type === SDK_TYPES.FAMILY ? t('a_common_copyToPersonal') : t('a_common_copyToFamily'),
        iconName: type === SDK_TYPES.FAMILY ? 'add_photo_alternate_1' : 'family',
        onClickItem: type === SDK_TYPES.FAMILY ? handleCopyToPersonalCloud : handleCopyToFamily
      },
      {
        label: t('a_common_createAlbumButton'),
        iconName: 'create_album',
        onClickItem: handleCreateNewAlbum
      },
      isSelectedItemsHaveOnlyFavorite() && (type === SDK_TYPES.DEFAULT) && {
        label: t('a_common_addToFavorites'),
        iconName: 'heart',
        onClickItem: handleAddToFavoritesItems
      },
      !isSelectedItemsHaveOnlyFavorite() && (type === SDK_TYPES.DEFAULT) && {
        label: t('a_common_removeFavorite'),
        iconName: 'remove_favorite',
        onClickItem: handleRemoveFromFavoritesItems
      },
      {
        label: t('a_common_createPublicLink'),
        iconName: 'link',
        onClickItem: handleSelectedItemsSharing.bind(null, albumData)
      },
      isEnabledPhotoDuplicates && selectedItems.length === 1 && selectedItems[0].type === 'image' && {
        label: t('a_common_searchForSimilarPhotos'),
        iconName: 'search_duplicate',
        onClickItem: handleDuplicate
      },
      !isPlace && !isCalendar && {
        label: t('a_common_deleteFromAlbum'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingItemsConfirmationModal
      },
    ].filter(item => !!item)
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = []
    if (!selectedItemsCount) {
      !isSeason && items.push({
        label: t('a_common_addToAlbum'),
        iconName: 'add_photo_alternate',
        onClickItem: handleAddPhoto.bind(null, SDK_TYPES.DEFAULT)
      })
    } else {
      items.push({
        label: t('a_common_download'),
        iconName: 'download',
        onClickItem: handleDownloadSelectedItems
      })
    }

    items = [...items, ...menuItems].filter((item) => !!item)

    return items
  })()

  return (
    <>
      <AlbumToolbarBox
        isPlace={isPlace}
        selectedItemsCount={selectedItemsCount}
        className={className}
      >
        {
          !selectedItemsCount && !isPlace && !isSeason && (
            <>
              {(type === SDK_TYPES.DEFAULT || type === SDK_TYPES.FAMILY) && !isPerson && !hideWithoutSelection && (
                <StyledPrimaryButton
                  actionName={t('a_common_addToAlbum')}
                  onAction={handleAddPhoto.bind(null, SDK_TYPES.DEFAULT)}
                />
              )}
            </>
          )
        }

        {
          selectedItemsCount > 0 && (
            <StyledPrimaryButton
              actionName={t('a_common_download')}
              onAction={handleDownloadSelectedItems}
            >
              {t('a_common_download')}
            </StyledPrimaryButton>
          )
        }

        <MenuWithActionItems menuItems={menuItems}
          isBorder={(isSeason || hideWithoutSelection) ? !!selectedItemsCount : true}
        />
      </AlbumToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={!!selectedItemsCount && (
          <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
        )}
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemoveAlbum')}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveAlbum}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={removingItemsConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: selectedItems.length })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveAlbumItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingItemsConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>
    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const AlbumToolbarBox = styled.div`
  padding-right: 24px;
  display: ${(props) => ((props.isPlace && (!!props.selectedItemsCount ? 'block' : 'none')))};
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  padding: 8px 12px;
`
