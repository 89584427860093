import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getFilesSdk, getFilesWS } from 'sdk/files'
import { OrderType, SelectType } from '@cloudike/web_ui_components'
import { IFsNodeSchema } from '@cloudike/web_files'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { t } from 'i18next'
import { goToSubscriptionsPage } from 'utils/subscriptions'
import { RootState } from 'store'
import _ from 'lodash'

import { checkTrashBinTotalItemsCountsThunk } from '../trashBinSlice'
import {
  selectAllFileListItemsReducer,
  selectFileListItemReducer, selectFileListItemWithPressedCtrlReducer, unselectAllFileListItemsReducer
} from "../../../utils/filesListSelection"
import { fetchFileFullData } from "../../../utils/files"
import { getErrorByFieldName } from "../../../utils/utils"


export enum SortColumns {
  NAME = 'name',
  MODIFIED = 'updated',
  SIZE = 'file_info.size',
}

export enum ReplaceModalTypes {
  REPLACE = 'replace',
  KEEP_BOTH = 'keep_both'
}

export enum CheckboxVisibilityType {
  VISIBLE = 'visible',
  HIDDEN = 'hidden'
}

const adapter = createEntityAdapter<IFsNodeSchema>()

export const filesSelectors = adapter.getSelectors()

const initialState = {
  status: LOADING_STATUSES.LOADING,
  error: null,
  sort: { by: SortColumns.NAME, direction: OrderType.ASK },
  selectedItemsIds: [],
  selectType: SelectType.NONE,
  checkboxVisibility: CheckboxVisibilityType.HIDDEN,
}

export const debouncedCheckTrashBinTotalItemsCounts = _.debounce((dispatch) => dispatch(checkTrashBinTotalItemsCountsThunk()), 500)

export const subscribeFilesTrashBinToWSThunk = createAsyncThunk(
  'trashBinFiles/subscribeFilesTrashBinToWSThunk',
  async function(__, { dispatch, getState }) {
    const filesWs = getFilesWS()

    filesWs.addEventListener('fs2', ({ output }) => {
      const state = getState() as RootState
      const changes = output?.changes || []
      const currentFilesItems = filesSelectors.selectAll(state.trashBinFiles)

      changes.forEach(async change => {
        if (change.action === 'deleted') {
          const existedNode = currentFilesItems.find(n => n.id === change.node_id)

          if (existedNode) {
            dispatch(actions.deleteItems([existedNode.id]))
            debouncedCheckTrashBinTotalItemsCounts(dispatch)
          }
        }

        if (change.action === 'changed') {
          const isTrashed = change.fields.is_explicitly_trashed

          if (!isTrashed) {
            const existedNode = currentFilesItems.find(n => n.id === change.node_id)

            if (existedNode) {
              dispatch(actions.deleteItems([existedNode.id]))
              debouncedCheckTrashBinTotalItemsCounts(dispatch)
            }
          } else {
            const node = {
              created: change.created,
              updated: change.updated,
              name: change.name,
              id: change.node_id,
              type: change.type,
              parent_id: change.parent_id,
              is_trashed: false,
              is_explicitly_trashed: false,
              is_shared: false,
              _embedded: change._embedded,
              ...(change?.fields ? change.fields : {})
            }

            const existedNode = currentFilesItems.find(n => n.id === node.node_id)

            if (!existedNode) {
              const data = await fetchFileFullData(state.user.userData.id, node.id)

              dispatch(actions.addItem(data))
              dispatch(checkTrashBinTotalItemsCountsThunk())
            }
          }
        }
      })
    })
  }
)

export const unsubscribeFilesTrashBinFromWSThunk = createAsyncThunk(
  'trashBinFiles/unsubscribeFilesTrashBinFromWSThunk',
  async function() {
    const filesWs = getFilesWS()

    filesWs.removeEventListener('fs2')
  }
)

export const fetchTrashBinFilesNodesThunk = createAsyncThunk(
  'trashBinFiles/fetchTrashBinFilesNodesThunk',
  async () => {
    const sdk = getFilesSdk()

    const response = await sdk.trashBin.getAllExplicitlyTrashedNodes({ preview: true, preview_jwt: true, embedded: true })

    return response
  }
)

export const fullRestoreFilesTrashBinThunk = createAsyncThunk(
  'trashBinFiles/fullRestoreTrashBinThunk',
  async function() {
    const sdk = getFilesSdk()

    try {
      showGlobalProgressLoader()
      await sdk.trashBin.fullRestore()

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_allItemsRestored')
      })
    } catch (error) {
      const details = getErrorByFieldName(error, 'details')
      const operationsError = details.operations[0]

      if (operationsError?.code === 'SizeQuotaExceeded') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          isPermanent: true,
          message: t('l_notification_restorePhotoError'),
          title: t('l_notification_spaceError'),
          callback: () => {
            goToSubscriptionsPage()
          }
        })
      } else {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_canNotComplete'),
          message: t('l_common_wentWrong')
        })
      }
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const emptyFilesTrashBinThunk = createAsyncThunk(
  'trashBinFiles/emptyTrashBinThunk',
  async function() {
    const sdk = getFilesSdk()

    try {
      showGlobalProgressLoader()
      await sdk.trashBin.emptyTrash()

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_trashEmpty')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_canNotComplete'),
        message: t('l_common_wentWrong')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const deleteFilesTrashBinItemsThunk = createAsyncThunk(
  'trashBinFiles/deleteTrashBinItemsThunk',
  async function({ ids }: { ids: string[]}, { dispatch }) {
    const sdk = getFilesSdk()

    try {
      showGlobalProgressLoader()
      await sdk.trashBin.deleteItems(ids)

      debouncedCheckTrashBinTotalItemsCounts(dispatch)
      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsDeletedforWeb', { number: ids.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_canNotComplete'),
        message: t('l_common_wentWrong')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const restoreFilesTrashBinSelectedItemsThunk = createAsyncThunk(
  'trashBinFiles/restoreTrashBinSelectedItemsThunk',
  async function({ ids } : {ids: string[]}, { dispatch }) {
    const sdk = getFilesSdk()

    try {
      showGlobalProgressLoader()
      await sdk.trashBin.restoreItems(ids)

      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsRestored', { number: ids.length })
      })
    } catch (error) {
      const errorCode = getErrorByFieldName(error,'code')

      if (errorCode === 'SizeQuotaExceeded') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          isPermanent: true,
          message: t('l_notification_restorePhotoError'),
          title: t('l_notification_spaceError'),
          callback: () => {
            goToSubscriptionsPage()
          }
        })
      } else {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_canNotComplete'),
          message: t('l_common_wentWrong')
        })
      }
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const trashBinFilesSlice = createSlice({
  name: 'trashBinFiles',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    addItem: (state, action) => {
      adapter.removeOne(state, action.payload.id)
      adapter.addOne(state, action.payload)
    },
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    selectItem: selectFileListItemReducer(filesSelectors),
    selectItemWithPressedCtrl: selectFileListItemWithPressedCtrlReducer(filesSelectors),
    setAllItems: selectAllFileListItemsReducer,
    unselectAll: unselectAllFileListItemsReducer,
    setSort: (state, action) => {
      state.sort = action.payload
    },
    deleteItems: (state, action: PayloadAction<string[]>) => {
      adapter.removeMany(state, action.payload)
    },
    resetState: (state) => {
      adapter.removeAll(state)

      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTrashBinFilesNodesThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchTrashBinFilesNodesThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload)
      })
      .addCase(fetchTrashBinFilesNodesThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

export default trashBinFilesSlice.reducer

const {
  reducer, actions
} = trashBinFilesSlice

export { reducer as trashBinFilesReducer, actions as trashBinFilesActions }
