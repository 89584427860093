import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import * as yup from 'yup'
import {
  DOMAINS_LIST,
  InputCollaborators,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SpriteIcon,
  STYLED_VARIABLES
} from "@cloudike/web_ui_components"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import QRCode from "react-qr-code"
import { Tooltip } from 'react-tooltip'
import _ from "lodash"

import { getModalsRootElement } from "../../constants/modals"
import { useAppDispatch } from "../../store"
import { getUserDataSelector } from "../user/selectors"
import { updateUserDataThunk } from "../user/userSlice"
import { useMobileDetection } from "../common/hooks"
import { getUserLogin } from "../../utils/user"
import APP_CONFIG from "../../constants/configs/app.config"

import { getFamilyDataSelector, getFamilyInviteModalIsOpenedSelector } from "./selectors"
import { createFamilyCloudThunk, familyActions, sendInvitesToFamily } from "./familySlice"

const deleteSpace = (text: string | undefined) => {
  if (!text) return
  return text.replace(/\s+/g, '')
}

export const FamilyInviteModal = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const familyData = getFamilyDataSelector()
  const userData = getUserDataSelector()
  const opened = getFamilyInviteModalIsOpenedSelector()
  const isMobile = useMobileDetection()
  const userLoginType = APP_CONFIG.user_login_type
  const mask = APP_CONFIG.user_phone_mask || ''
  const minMaskPhoneNumber = APP_CONFIG.min_mask_phone_number || ''

  const [qrShowed, toggleQr] = useState(false)
  const [notificationText, setNotificationText] = useState('')

  const [familyMembers, setFamilyMembers] = useState<any[]>([])

  const [maxHeightModal, setMaxHeightModal] = useState('469px')

  const notificationTimeoutRef = useRef(null)
  const formInputCollaboratorsRef = useRef<any>()

  const validationSchemaFixedCountNumberPhone = useMemo(() => {
    return yup.object().shape({
      phoneNumber: yup
        .string()
        .required(t('l_common_incorrectPhoneNumberError'))
        .length(mask.replace('\\', '').length, t('l_common_incorrectPhoneNumberError')),
    })
  }, [])

  const validationSchemaRangeNumberPhone = useMemo(() => {
    return yup.object().shape({
      phoneNumber: yup
        .string()
        .required(t('l_common_incorrectPhoneNumberError'))
        .min(minMaskPhoneNumber.length, t('l_common_incorrectPhoneNumberError'))
        .max(mask.replace('\\', '').length, t('l_common_incorrectPhoneNumberError'))
    })
  }, [])

  const validationSchemaEmail = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .required(t('l_notification_incorrectEmailError'))
        .email(t('l_notification_incorrectEmailError'))
        .test('checkDomain', t('l_notification_incorrectEmailError') , function (value) {
          value = value ? value.toString() : ''
          let isDomain = false
          for (let i = 0; i < DOMAINS_LIST.length; i++) {
            if (value.includes(DOMAINS_LIST[i])) {
              isDomain = true
              break
            }
          }
          return isDomain
        })
    })
  }, [])

  const ValidationSchemaInputCollaborators = userLoginType === 'phoneNumber' ?
    !!minMaskPhoneNumber ? validationSchemaRangeNumberPhone : validationSchemaFixedCountNumberPhone
    : validationSchemaEmail

  useEffect(() => {
    if(opened && !userData?.family_user_id) {
      dispatch(createFamilyCloudThunk({}))
    }
  }, [opened, userData?.family_user_id])

  useEffect(() => {
    if (!opened) {
      setNotificationText('')
      toggleQr(false)
      setFamilyMembers([])
    }
  }, [opened])

  const link = familyData?.invite_hash ? `${window.location.origin}/invite?fchash=${familyData.invite_hash}` : ''

  const handleToggleQr = () => {
    toggleQr(!qrShowed)
    qrShowed ? setMaxHeightModal('469px') : setMaxHeightModal('650px')
  }

  const handleClose = () => {
    dispatch(updateUserDataThunk({ family_user_id: familyData?.shared_user_id, is_owner_family: userData?.id === familyData?.owner_id }))
    dispatch(familyActions.toggleFamilyinviteModal(false))
  }

  const handleCopyLink = () => {
    clearTimeout(notificationTimeoutRef.current)

    navigator.clipboard.writeText(link)

    setNotificationText(t('l_notification_linkCopied'))

    notificationTimeoutRef.current = setTimeout(() => {
      setNotificationText('')
    }, 3000)
  }

  const handleSetFamilyMembers = useCallback((members?: any) => {
    setFamilyMembers(members)
  }, [])

  const handleSetFamilyMembersInputError = (error) => {
    setNotificationText(error)

    notificationTimeoutRef.current = setTimeout(() => {
      setNotificationText('')
    }, 3000)
  }

  const handleSendInvitesAndClose = () => {
    if (!formInputCollaboratorsRef.current) {
      return
    }

    const currentMembers = [...familyMembers]

    formInputCollaboratorsRef.current.handleSubmit()
    const valueFromInput = formInputCollaboratorsRef.current['values'][userLoginType]

    if (formInputCollaboratorsRef.current['errors'][userLoginType]) {
      const textNotification = formInputCollaboratorsRef.current['errors'][userLoginType]

      if (valueFromInput.length > 0) {
        handleSetFamilyMembersInputError(textNotification)

        return
      }
    }

    const to = [...currentMembers.map(member => member.phone_or_email)]

    if (valueFromInput) {
      to.push(valueFromInput)
    }

    dispatch(sendInvitesToFamily({ to: _.uniq(to.map(elem => deleteSpace(elem))) }))
  }

  return (
    <SModalDialog
      header={(
        <SHeaderBox>
          {!!notificationText && (
            <SNotification>
              {notificationText}
            </SNotification>
          )}

          <SModalHeader title={t('l_familycloud_inviteFamily')}
            description={t('l_familycloud_inviteFamilyInfo')}
          />

          <SHeaderCloseButton iconName="close"
            onClick={handleClose}
          />
        </SHeaderBox>

      )}
      footer={(
        <SModalFooter
          buttonList={[
            <SSecondaryButton
              key={'a_common_cancel'}
              actionName={t('a_common_cancel')}
              className="modal-dialog__cancel"
              onAction={handleClose}
            />,
            <PrimaryButton
              key='a_common_done'
              actionName={t('a_common_done')}
              onAction={handleSendInvitesAndClose}
            />
          ]}
          withoutShadowLine
        />
      )}
      parentBlock={getModalsRootElement()}
      isOpened={opened}
      onCloseModal={handleClose}
    >
      <SContent>
        <SLabelRow>
          {t('l_familycloud_inviteByLink')}

          <InfoLabelTooltipBox
            data-tooltip-id="date-info-tooltip"
            data-tooltip-content={t('l_notification_inviteByLink')}
            data-tooltip-place="top"
          >
            <SInfoIcon iconName='info_outline' />
          </InfoLabelTooltipBox>
        </SLabelRow>

        <SInputBox>
          <SLinkIcon iconName='ic_link' />

          <SInput>
            {link}
          </SInput>

          <SCopyIcon
            iconName="copy_stroke"
            onClick={handleCopyLink}
          />
        </SInputBox>

        <SQrToggle onClick={handleToggleQr}>
          {t('l_familycloud_showQRcode')}

          <SExpandIcon iconName='expand_more'
            className={classNames({ rotated: qrShowed })}
          />
        </SQrToggle>

        {qrShowed && (
          <SQrCodeWrapper>
            <SQrCode value={link} />
          </SQrCodeWrapper>
        )}

        <STooltip id="date-info-tooltip"
          openOnClick={isMobile}
        />

        <SDivider />

        <SLabelRow>
          {userLoginType === 'phoneNumber' ? t('l_familycloud_inviteByPhone') : t('l_familycloud_inviteByEmail')}

          <InfoLabelTooltipBox
            data-tooltip-id="date-info-tooltip"
            data-tooltip-content={userLoginType === 'phoneNumber' ? t('l_notification_inviteByPhone') : t('l_notiffication_inviteByEmail')}
            data-tooltip-place="top"
          >
            <SInfoIcon iconName='info_outline' />
          </InfoLabelTooltipBox>
        </SLabelRow>

        <InputCollaborators
          validationSchema={ValidationSchemaInputCollaborators}
          startArrayCollaborator={familyMembers}
          listCollaborators={[]}
          onSetArray={handleSetFamilyMembers}
          onError={handleSetFamilyMembersInputError}
          ownerNumberPhoneOrEmail={getUserLogin(userData)}
          typeInput={userLoginType}
          textsInput={{
            PHONE_PLACEHOLDER: t('l_common_enterPhoneplaceholder'),
            EMAIL_PLACEHOLDER: t('l_common_enterEmailplaceholder'),
            DESCRIPTION: t('l_common_numberConnection'),
          }}
          formInputCollaboratorsRef={formInputCollaboratorsRef}
          maxCount={50}
          userIcon='user_outlined'
          mask={mask}
        />

        {userLoginType === 'phoneNumber' && (
          <SInviteMessage>
            {t('l_common_numberConnection')}
          </SInviteMessage>
        )}
      </SContent>
    </SModalDialog>
  )
}

const SModalDialog = styled(ModalDialog)`
  .modal-container {
    overflow: auto;
    height: fit-content;
    max-height: 90vh;
  }
`

const SContent = styled.div`
  width: 100%;
  max-width: 340px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 580px;
    max-width: 580px;
    padding-bottom: 24px;
  }
`

const SModalFooter = styled(ModalFooter)`
  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    && {
      flex-direction: column;
      width: 100%;
      height: 136px;
      justify-content: center;

      .modal-buttons-container {
        flex-direction: column-reverse;
        width: 100%;
      }
    }
  }
`

const SSecondaryButton = styled(SecondaryButton)`
  margin-right: 8px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-right: 0;
    margin-top: 8px;
  }
`

const SHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    border-bottom: 1px solid var(--divider-primary);
  }
`

const SModalHeader = styled(ModalHeader)`
  position: relative;
  
  .modal-header-description {
    margin-top: 9px;
    max-width: 508px;
  }
`

const SHeaderCloseButton = styled(SpriteIcon)`
  color: var(--icon-primary);
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    position: absolute;
    top: 24px;
    right: 24px;
    margin-top: 0;
    margin-right: 0 ;
  }
`

const SLabelRow = styled.div`
  display: flex;
  align-items: center;
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 22px;
  }
`

const SInputBox = styled.div`
  margin-top: 5px;
  width: 100%;
  min-height: 40px;
  border-radius: 4px;
  background: var(--background-tertiary);
  display: flex;
  align-items: center;
  padding: 0 16px;
`

const SLinkIcon = styled(SpriteIcon)`
  width: 24px;
  height: 24px;
  color: var(--icon-primary);
`

const SInput = styled.div`
  margin-left: 8px;
  height: 100%;
  flex: 1;
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 8px 0;
`

const SCopyIcon = styled(SpriteIcon)`
  && {
    margin-left: auto;
    width: 24px;
    height: 24px;
    color: var(--accent-normal);
    cursor: pointer;
  }
`

const SQrToggle = styled.div`
  margin-top: 9px;
  display: flex;
  align-items: center;
  color: var(--accent-normal);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  cursor: pointer;
`

const SExpandIcon = styled(SpriteIcon)`
  && {
    margin-left: 6px;
    width: 16px;
    height: 16px;
    
    &.rotated {
      transform: rotate(180deg);
    }
  }
`

const SQrCodeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const SQrCode = styled(QRCode)`
  width: 160px;
  height: 160px;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--white);  
`

const STooltip = styled(Tooltip)`
  && {
    min-height: 56px;
    word-wrap: break-word;
    max-width: 220px;
    border-radius: 6px !important;
    z-index: 3;
    font-size: 14px;
    text-align: center;
  }
`

const InfoLabelTooltipBox = styled.a`
  margin-left: 2px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SInfoIcon = styled(SpriteIcon)`
  && {
    width: 16px;
    height: 16px;
    color: var(--ink-light);
  }
`

const SNotification = styled.div`
  padding: 6px 8px;border-radius: 4px;
  background: #252A31;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  z-index: 3;
`

const SDivider = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background: var(--divider-primary);

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-bottom: 24px;
  }
`

const SInviteMessage = styled.p`
  margin-top: 8px;
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
