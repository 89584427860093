import React, { useCallback, useEffect } from 'react'

import styled from 'styled-components'
import { useAppDispatch } from "store"
import { useInView } from 'react-intersection-observer'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { openPhotoPreviewThunk, PREVIEW_TYPES } from 'features/photo/photo-preview/photoPreviewSlice'
import { PageSpiner, PhotoCard, PhotosGrid, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { usePreviewItemsUpdate } from 'features/photo/photo-preview/usePreviewItemsUpdate'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'
import { uploadSharedAlbumItemsThunk } from 'features/common/files-uploading/filesUploadingSlice'
import { useTranslation } from 'react-i18next'
import { getIsUserAuthorizedSelector } from 'features/user/userSlice'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { MobileToolbarMenuToggle } from 'features/common/right-sidebar/MobileToolbarMenuToggle'

import {
  getPublicLinkItemsSelector,
  getPublicLinkPermissionSelector,
  getPublicLinkSelectedItemsCountSelector,
  getPublicLinkSelectedItemsIdsSelector,
  getPublicLinksNotMyResourceHrefSelector,
  getPublicLinksTokenSelector,
  getPublicLinkTotalItemsCountSelector
} from './selectors'
import { checkForAccessToSharedAlbumThunk, loadMorePublicLinksItemsThunk, publicLinkActions } from './publicLinkSlice'
import { PublicLinkPlaceholder, PublicLinkPlaceholderBtnTypes } from './PublicLinkPlaceholder'

interface PublicAlbumProps {
    className?: string,
}

export const PublicAlbum: React.FC<PublicAlbumProps> = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const isAuthorized = getIsUserAuthorizedSelector()
  const items = getPublicLinkItemsSelector()
  const totalItemsCount = getPublicLinkTotalItemsCountSelector()
  const selectedItemsCount = getPublicLinkSelectedItemsCountSelector()
  const selectedItemsIds = getPublicLinkSelectedItemsIdsSelector()
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()
  const permission = getPublicLinkPermissionSelector()

  const token = getPublicLinksTokenSelector()
  const notMyResourceHref = getPublicLinksNotMyResourceHrefSelector()

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const isDropzoneDisabled = !isAuthorized || permission !== 'write'

  const loadMore = () => {
    dispatch(loadMorePublicLinksItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items])

  usePreviewItemsUpdate(items, totalItemsCount)

  const handleItemSelection = useCallback((id) => {
    dispatch(publicLinkActions.selectItem(id))
  }, [])

  const handleOpenPreview = useCallback((id) => {
    dispatch(openPhotoPreviewThunk({
      items,
      currentItemId: id,
      sdkType: SDK_TYPES.SHARED,
      type: PREVIEW_TYPES.SHARED_ALBUM,
      totalItemsCount,
      loadMore
    }))
  }, [items, totalItemsCount])

  const handleUploadFilesFromDropzone = (acceptedFiles: FileList) => {
    const callback = () => {
      if (acceptedFiles.length) {
        dispatch(uploadSharedAlbumItemsThunk({ files: acceptedFiles, url: notMyResourceHref, token }))
      }
    }

    dispatch(checkForAccessToSharedAlbumThunk({ callback, neededPermission: 'write' }))
  }

  const handleUploadFiles = (event) => {
    const files = event.target.files

    const callback = () => {
      if (files.length) {
        dispatch(uploadSharedAlbumItemsThunk({ files, url: notMyResourceHref, token }))
      }

      event.target.value = ''
    }

    dispatch(checkForAccessToSharedAlbumThunk({ callback, neededPermission: 'write' }))
  }

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFilesFromDropzone }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: isDropzoneDisabled,
    }))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
    }
  }, [])

  const placeholderBtnText = isAuthorized ? permission === 'write' ? t('a_common_uploadPhotos') : t('a_common_backToMyFiles') : t('a_common_logIn')

  if (!items.length) {
    return (
      <AlbumBox
        className={className}
      >
        <PublicLinkPlaceholder
          texts={{
            title: t('l_albums_noPhotosTitle'),
            description: '',
            btn: placeholderBtnText
          }}
          btnType={PublicLinkPlaceholderBtnTypes.SECONDARY}
          onButtonClick={isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO()}
          isFileUploading={isAuthorized && permission === 'write'}
          onUpload={handleUploadFiles}
        />

        {
          permission === 'write' && !isDropzoneDisabled && (
            <DropzoneArea
              visible={isDropzoneAreaVisible}
            />
          )}

        {
          permission === 'write'
                    && <StyledMobileToolbarMenuToggle />
        }
      </AlbumBox>
    )
  }

  return (
    <AlbumBox
      className={className}
    >
      <AlbumItemsBox>
        <PhotosGrid>
          {items.map(item => (
            <PhotoCard
              key={item.id}
              id={item.id}
              imgUrl={(item._links as any)?.image_middle?.href}
              isSelected={selectedItemsIds.includes(item.id)}
              onSelect={handleItemSelection}
              onClick={handleOpenPreview}
              isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
              type={item.type}
            />
          ))}
        </PhotosGrid>

        {items.length < totalItemsCount && (
          <div ref={loaderBoxRef}>
            <StyledPageSpinner />
          </div>
        )}
      </AlbumItemsBox>

      <DropzoneArea
        visible={isDropzoneAreaVisible}
      />

      <StyledMobileToolbarMenuToggle />
    </AlbumBox>
  )
}

const AlbumBox = styled.div`
  width: 100%;
  position: relative;
  height: calc(100% - 107px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &.with-overflow {
    max-height: calc(100vh - 120px);
    overflow: hidden;
  }
`

const AlbumItemsBox = styled.div`
  width: 100%;
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`

const StyledMobileToolbarMenuToggle = styled(MobileToolbarMenuToggle)`

  svg {
    color: var(--background-primary)
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none !important;
  }
`
