import { uniqueId } from "lodash"

import { INotification, NotificationsListener } from "./types"

const notifications: INotification[] = []

const listeners: NotificationsListener[] = []

let DEFAULT_TIMEOUT_TO_HIDE = 5000

const callAllListeners = () => {
  listeners.forEach(listener => {
    listener([...notifications])
  })
}

export const setTimeoutToHideNotification = (timeout: number) => {
  DEFAULT_TIMEOUT_TO_HIDE = timeout
}

export const addNotificationsListener = (listener: NotificationsListener) => {
  listeners.push(listener)
}

export const showNotification = (notification: INotification) => {
  if (!!notification.typeError && notifications.some(item => item.typeError === notification.typeError)) {
    return
  }

  const notificationWithId = { ...notification, id: uniqueId() }
  notifications.push(notificationWithId)

  callAllListeners()

  if (!notification.isPermanent) {
    setTimeout(() => {
      const existedNotification = notifications.find(n => n.id === notificationWithId.id)
      const indexOfNotification = notifications.indexOf(existedNotification)

      notifications.splice(indexOfNotification, 1)

      callAllListeners()
    }, notification.timeoutToHide || DEFAULT_TIMEOUT_TO_HIDE)
  }

  return notificationWithId
}

export const hideNotification = (notification: INotification) => {
  const existedNotification = notifications.find(n => n.id === notification.id)
  const indexOfNotification = notifications.indexOf(existedNotification)

  if (!existedNotification) {
    return
  }

  notifications.splice(indexOfNotification, 1)

  callAllListeners()
}
